@import 'src/styles/colors.scss';

.import-premium-data-container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 452px;
    padding: 0 10px;
}

.import-premium-data-dropzone {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background-color: $allocatorContainerBackgroundColor;
    border: 1px solid $grey;
    border-radius: 2px;
    min-height: 200px;
    cursor: pointer;
    margin-top: 12px;
    
    .import-premium-data-loading {
        display: flex;
        flex-direction: column;

        p {
            margin: 0 0 6px 6px;
        }
    }

    .import-premium-data-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        p {
            margin: 12px 0 0;
            text-align: center;
        }
    }
}