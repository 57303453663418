
.agent-home-container {
    height: 100%;
    text-align: right;
    width: 100%;

    & .chart-header {
        font-weight: bold;
        margin: 16px 0px -39px 0px;
        text-align: center;
    }
}
