@import './styles/colors.scss';

html, body, #root {
  height: 100vh;
  overflow: hidden;
  line-height: 1.5;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}


body {
  background-color: $bodyBackgroundColor;
  color: #000;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

.application-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.product-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.loader-container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
  height: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
  min-height: 80px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}