@import 'src/styles/colors.scss';

.e-signature-modal-container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 460px;
    padding: 0 10px;

    .e-signature-modal-actions {
        display: flex;
        justify-content: space-between;
        padding-top: 10px;
    }
}