.spaced-text-container {
    position: absolute;
    cursor: text;
    white-space: nowrap; /* Prevent text from breaking into multiple lines */
    overflow: hidden; /* Hide any overflowed text */
}

.spaced-text-char {
    display: inline-block; /* Keep characters in a single line */
    position: absolute; 
    background-color: transparent;
    text-align: center;
    width: 10px;
}
