
.general-container {
    .header {
        display: inline;
        font-weight: bold;
        margin: 8px 0px;
    }
    .subheader {
        margin: 0px 8px 0px 0px;
    }
    .file-uploader{
        display: flex;
        align-items: center;
        margin: 10px 0px;
        white-space: nowrap;

        label {
            min-width: auto;
        }
    }
    .file-name {
        margin: 0 10px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
