@import "src/styles/colors.scss";

.text-field-container {
    align-items: center;
    display: flex;
    border-radius: 6px;
    position: absolute;
    z-index: 10;

    .text-field-input {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border: none;
        border-radius: 6px;
        font-family: inherit;
        resize: none
    }
}
