
@import 'src/styles/colors.scss';

.attachments-container {
    display: flex;
    flex-direction: column;
    background-color: $white;
    height: 100%;
    min-height: 300px;
    padding: 16px;

    .attachments-list {
        height: 50%;
        margin-bottom: 12px;
        overflow-y: auto;
    }

    .file-upload {
        height: 25%;
    }

    .icons-container {
        display: flex;
        height: 25%;
        justify-content: space-between;
    }
    .no-attachments {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%;
    }
}
