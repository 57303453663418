@import 'src/styles/colors.scss';

.error-log-container {
    background-color: $allocatorComponentBackgroundLightColor;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 30px;
    overflow-y: auto;

    .error-log-landing {
        background-color: $white;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 600px;
        margin: auto;
        padding: 20px 24px;
        border: 1px solid $grey;
        border-radius: 4px;

        .error-log-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .MuiIconButton-root {
                color: $primaryColor;
            }

            h6 {
                color: $black;
                font-size: 20px;
                margin: 0;
            }
        }

        ul {
            padding-left: 30px;
        }
    }
}
