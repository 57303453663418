@import 'src/styles/component.scss';

.account-admin-sidebar {
    display: flex;
    flex-direction: column;
    height: calc(100% - 60px);
    color: $light;
    background-color: #354560;
    padding: 16px;

    &--collapsed {
        visibility: hidden;
    }

    .title {
        font-size: larger;
        text-align: center;
        padding-bottom: 20px;
    }
    .button {
        color: $light;
        background-color: $indigo_600;
        margin-bottom: 20px;
    }

    .account-list-container {
        padding-bottom: 10px;
    }

}


.account-admin-content {
    width: 100%;
    height: 100%;
    background-color: $ghost-white;
    scrollbar-color: $indigo_800 $ghost-white;
    overflow-y: scroll;

    .tab-list {
        background-color: $light;
        width: 100%;
        border-bottom: 1px solid #E0E0E0;
        position: fixed;
        z-index: 1;

        .tab {
            font-weight: bold;
            color: $primary;
        }
    }

    .tab-panel {
        margin-top: 40px;
    }

}