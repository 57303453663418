@import 'src/styles/colors.scss';

.upload-tab-dropzone {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background-color: $allocatorContainerBackgroundColor;
    border: 1px solid $grey;
    border-radius: 2px;
    min-height: 65px;
    height: 65px;
    cursor: pointer;
    margin: 16px 0;
    position: relative;

    .upload-tab-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        &::before {
            position: absolute;
            bottom: 17px;
            left: 0;
            content: " ";
            border-bottom: 1px solid $grey;
            width: 100%;
        }

        &::after {
            position: absolute;
            top: 0;
            left: 18px;
            content: " ";
            border-right: 1px solid $grey;
            height: 100%;
        }

        p {
            margin: -2px 0 0;
            text-align: center;
        }

        .upload-tab-hint {
            color: #777777;
            font-size: 12px;
            margin-top: 0;
        }
    }
}

.signature-preview-img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    z-index: 10;
}