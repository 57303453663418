
@import 'src/styles/colors.scss';

.return-tree-accordion-container {
    color: $textColor;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: calc(100% - 60px);  

    .company-not-selected {
        text-align: center;
    }
}