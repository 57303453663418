@import "src/styles/colors.scss";

.check-field-container {
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    margin: 0px;
    padding: 0px;
    position: absolute;
    z-index: 10;

    &--locked {
        cursor: not-allowed;
    }

    .checkbox-input {
        position: absolute;
        margin: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    .darken-overlay {
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }

    .x-overlay {
        border-radius: 5px;
    }
}
