
.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    width: 100%;
    .loader-text {
        position: absolute;
    }
}
