.EFileModalContainer{
    background: white;
    height: 100%;

    .EFileModalContent{
        display:flex;
        height: 100%;
        flex-direction: column;

        .efile-tab-container{
            min-width: 150px;
            background-color: #354560;
            button{
                text-transform: none;
                align-items: start;
                color: white;
                background-color: #354560;
            }
            .MuiTabs-indicator{
                background-color: white;
            }
        }
        .efile-tabpanel-title{
            font-size: 14px;
            display: flex;
            border-bottom: 3px solid #354560;
            width: 100%;
            font-weight: bold;
            margin-bottom: 15px;
        }
        a{
            color: #354560;
            padding-left: 20px;
        }

        #efile-tabpanel-instructions{
            font-size: 14px;
            padding: 24px;
            overflow-y: auto;
        }

        #efile-tabpanel-review{
            font-size: 14px;
            padding: 24px;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
        }

        .efile-tabpanel-errors{
            font-size: 14px;
            padding: 24px;
            width: 100%;
            overflow-y: auto;
        }

        .efile-email-property{
            display: grid;
            grid-template-columns: 90px auto;
            grid-template-rows: auto auto;
            gap: 10px;
            .efile-email-property-name{
                color: black;
                font-weight: bold;
                text-align: right;
                padding: 2px;
            }
            .efile-email-property-value{
                display: flex;
                flex-wrap: wrap;
                border-bottom: 1px solid #354560;
                .efile-email{
                    border: 1px solid #354560;
                    background-color: rgba(71, 128, 222, 0.3);
                    border-radius: 10px;
                    padding: 0 8px;
                    margin-right: 5px;
                    margin-bottom: 5px;
                    
                }
            }
        }
        .efile-tabpanel-attachments{
            width: 100%;
            resize: vertical;
            min-height: 100px;
            margin-bottom: 20px;
        }
        .efile-tabpanel-body{
            width: 100%;
            resize: horizontal;
            min-height: 50px;
        }
    }
    .efile-send-email-button{
        align-self: self-end;
        margin-right: 20px;
        background-color: #354560;
        color: white;
        text-transform: none;
        padding: 5px 20px;
        &:hover {
            background-color: #3b4d6c; 
        }
    }
    .TabPanelContent{
        display: flex;
        flex-direction: column;
        flex: 1;
        height: calc(100% - 50px);
        overflow-y: auto;
        padding: 24px;

        .action-buttons {
            display: flex;
            flex-direction: row;
            justify-content: end;
            padding: 16px 0;
            margin-bottom: 20px;
        }
    }
}
