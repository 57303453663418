
.extensive-search-modal-container {
    .list-item-icon {
        display: flex;
        margin-right: 10px;
    }
    .search-result-count {
        margin-top: 20px;
        width: 100%;
    }
}
