@import "src/styles/component.scss";

.tts-modal {

    .MuiDialog-paper {
        border-radius: $spacing-8;
    }

    .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: $primary;
        padding: $spacing-8 $spacing-16;

        &__title {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 2px;
            color: $light;
            font-size: 16px;
        }
        &__close-icon {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 2px;
            color: $light;
            margin-left: auto;
            &:hover {
                cursor: pointer;
            }
        }
    }

    .content {
        padding: $spacing-8 !important;

        /* Custom Scrollbar Styles */
        &::-webkit-scrollbar-thumb {
            background-color: #abb1bb;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: #5e6a80; 
        }
    }

}