@import 'src/styles/colors.scss';

.tools-container {
    background-color: $allocatorComponentBackgroundLightColor;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 30px;
    overflow-y: auto;

    .tools-landing {
        background-color: $allocatorProductBackgroundColor;
        max-width: 500px;
        margin: auto;
        border: 1px solid $grey;
        border-radius: 4px;

        .tools-header {
            background-color: $primaryColor;
            color: $white;
            padding: 20px 24px;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        }

        .tools-tabs {
            display: flex;
            margin-top: 20px;
        }

        .tools-content {
            padding: 20px 24px;
        }
    }
}
