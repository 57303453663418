.efile-modal {
    background: white;
    height: 100%;
    width: 100%;
    display: flex;

    &-content{
        display:flex;
        width: 100%;
        flex-direction: column;

        .efile-tab-container{
            min-width: 150px;
            background-color: #354560;
            button{
                text-transform: none;
                align-items: start;
                color: white;
                background-color: #354560;
            }
            .MuiTabs-indicator{
                background-color: white;
            }
        }
        .efile-tabpanel-title{
            font-size: 14px;
            display: flex;
            border-bottom: 3px solid #354560;
            width: 100%;
            font-weight: bold;
            margin-bottom: 15px;
        }
        .efile-tabpanel-legend{

            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;

            &-item {
                display: flex;
                align-items: center;
                font-weight: bold;
                margin-right: 20px;
                .description {
                    margin-left: 5px;
                }
            }

        }
        a{
            color: #354560;
            padding-left: 20px;
        }

        #efile-tabpanel-instructions{
            font-size: 14px;
            padding: 24px;
            height: 80%;
            overflow-y: auto;
        }

        #efile-tabpanel-review{
            font-size: 14px;
            padding: 24px;
            display: flex;
            flex: 1;
            flex-direction: column;
            overflow-y: auto;
        }
        
        .efile-tabpanel-errors{
            font-size: 14px;
            padding: 24px;
            width: 100%;
            overflow-y: auto;
        }
    }
    .efile-send-button{
        align-self: self-end;
        margin-right: 20px;
        background-color: #354560;
        color: white;
        text-transform: none;
        padding: 5px 20px;
        &:hover {
            background-color: #3b4d6c; 
        }
    }
    .tab-panel-content{
        display: flex;
        flex-direction: column;
        flex: 1;
        height: calc(100% - 50px);
        padding: 24px;
        overflow-y: auto;
    }

    .success-container {
        background-color: #f9f9f9;
        border: 1px solid #ddd;
        border-radius: 8px;
        padding: 20px;
        max-width: 600px;
        margin: 20px auto;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    
    .success-description {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    
    .success-link {
        color: #007BFF;
        text-decoration: none;
        font-size: 16px;
        margin-bottom: 10px;
        display: block;
    }
    
    .success-link:hover {
        text-decoration: underline;
    }
    
    .success-confirmation {
        font-size: 16px;
        color: #555;
        margin-top: 10px;
    }
}
