@import 'src/styles/colors.scss';

.import-sbp-data-dropzone {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background-color: $allocatorContainerBackgroundColor;
    border: 1px solid $grey;
    border-radius: 2px;
    min-height: 200px;
    cursor: pointer;
    margin-top: 12px;

    .import-sbp-data-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        p {
            margin: 12px 0 0;
            text-align: center;
        }
    }
}

.form-control-label{
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
    border: 0 solid #ccc;
    padding: 10px;
    border-radius: 5px;
}

.state-check-status {
    height: 300px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
}