@import "./palette";
@import "./spacing";
@import "./fonts";

:root {
    // Palette
    --tts-primary: #{$indigo_500};
    --tts-secondary: #{$indigo_600};
    --tts-dark: #{$dark_500};
    --tts-light: #{$white};
    --tts-accent-light: #{$indigo_300};
    --tts-accent-dark: #{$yellow};
    --tts-danger: #bb0000;
    --tts-warning: #ffa200;
    --tts-success: #278127;
    --tts-info: #0092b7;
    --tts-transparent: transparent;

    // Font Size
    --base-font-size: 16px;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
