
@import 'src/styles/colors.scss';

.optins-export-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.optins-tabpanel-errors{
    font-size: 14px;
    padding: 24px;
    overflow-y: auto;
}

.optins-tabpanel-title{
    font-size: 14px;
    display: flex;
    border-bottom: 3px solid #354560;
    width: 100%;
    font-weight: bold;
    margin-bottom: 15px;
}