@import 'src/styles/colors.scss';

.agent-right-container {
    background-color: $agentRightContainerBackgroundColor;
    display: flex;
    height: 100%;
    width: 100%;
    padding: 16px;
    overflow-y: scroll;
}
