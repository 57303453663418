@import "src/styles/colors.scss";

.toolbar-container {
    width: 50px;
    align-items: center;
    background-color: $bodyBackgroundColor;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    z-index: 20;
    border-radius: 20px 0 0 20px;
    overflow: hidden;
}

.toolbar-icon-container {
    height: 64px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        cursor: pointer;
        background-color:rgba(153, 182, 221, 0.3);
    }

    &.selected {
        background-color: rgba(153, 182, 221, 0.3);
    }
}