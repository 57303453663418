@import "src/styles/component";

.tts-return-tree-viewer {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: #354560;
    box-sizing: border-box;
    padding: 8px;

    .filters {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-bottom: 5px;

        &__section {
            display: flex;
            width: 100%;
            flex-direction: row;
            align-items: center;
            padding: 5px;

            &--collapsed {
                flex-direction: column;
            }

            .selector {
                padding: 5px;
                width: 100%;
                &--small {
                    width: 50%;
                }
            }
        }
    }

    .tree {
        display: flex;
        flex-direction: column;
        height: 100%;
        border-top: 1px dashed $light;
        padding-top: 5px;
        overflow: hidden;

        &--collapsed {
            visibility: hidden;
        }

        .loader-section {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;

            .loader-text {
                text-align: center;
                margin: 10 0;
                color: white;
            }
        }

    }

}
